import { graphql, navigate, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  Accordion,
  Block2,
  Box,
  Boxes,
  Button,
  Container,
  Image,
  Link,
  LinkProps,
  Page,
  Row2,
  Text,
  TextProps,
  Title,
  Zip,
} from 'src/components';
import paths from 'src/constants/paths';
import urls from 'src/constants/urls';
import { locale, $t } from 'src/utils';
import { ReactComponent as AetnaCvsHealthLogoSvg } from './images/aetna-cvs-health-logo.svg';
import goalsImage from './images/benefits/goals@2x.png';
import insuranceImage from './images/benefits/insurance@2x.png';
import taxesImage from './images/benefits/taxes@2x.png';
import { ReactComponent as AetnaHandSvg } from './images/value-props/aetna-hand.svg';
import { ReactComponent as CalculatorSvg } from './images/value-props/calculator.svg';
import { ReactComponent as DoctorSvg } from './images/value-props/doctor.svg';
import { ReactComponent as TabletSvg } from './images/value-props/tablet.svg';

const QUERY = graphql`
  query Aetna {
    enFaq: allGraphCmsFaq(filter: { contexts: { in: aetna_faq }, locale: { eq: en } }) {
      nodes {
        explanation
        title
      }
    }
    esFaq: allGraphCmsFaq(filter: { contexts: { in: aetna_faq }, locale: { eq: es } }) {
      nodes {
        explanation
        title
      }
    }
    enGuides: allGraphCmsFaq(
      filter: { contexts: { in: aetna_health_enrollment_guide }, locale: { eq: en } }
    ) {
      nodes {
        explanation
        title
      }
    }
    esGuides: allGraphCmsFaq(
      filter: { contexts: { in: aetna_health_enrollment_guide }, locale: { eq: es } }
    ) {
      nodes {
        explanation
        title
      }
    }
  }
`;

function AetnaCvsHealthSignUpText({ color = 'purple' }: { color?: LinkProps['color'] }) {
  return (
    <Text element="p" flush>
      {$t({
        id: 'aetna.existing.question',
        defaultMessage: 'Already have an Aetna CVS Health plan?',
      })}{' '}
      <Link to={urls.SIGN_UP} color={color}>
        <Text weight={color === 'inherit' ? 'bold' : undefined}>
          {$t({
            id: 'aetna.existing.link',
            defaultMessage: 'Start saving with Catch.',
          })}
        </Text>
      </Link>
    </Text>
  );
}

const Hero = styled.header`
  padding: 180px var(--side-margin) 116px;
`;

const HeroInner = styled.header`
  display: flex;
  align-items: center;
  gap: 72px;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const SAetnaCvsHealthLogoSvg = styled(AetnaCvsHealthLogoSvg)`
  max-width: 100%;
  height: auto;
`;

const HeroImage = styled(Text)`
  width: 100%;
`;

const ValuePropsGrid = styled.div`
  --columns: 4;
  display: grid;
  gap: 48px 56px;
  grid-template-columns: repeat(var(--columns), 1fr);
  margin: 0 0 72px;

  @media (max-width: 980px) {
    --columns: 2;
  }

  @media (max-width: 499px) {
    --columns: 1;
  }
`;

const BenefitsGrid = styled.div`
  --columns: 3;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(var(--columns), 1fr);

  @media (max-width: 980px) {
    --columns: 1;
    max-width: 384px;
    margin: auto;
  }
`;

const Benefit = styled(Text)`
  background: var(--c-bg-2);
  border-radius: 32px;
  padding: 32px;
`;

const BenefitImage = styled(Image)`
  display: block;
  margin: auto;

  @media (prefers-color-scheme: dark) {
    filter: invert(100%) saturate(0) brightness(500%);
  }

  ${({ $shadow }) =>
    $shadow &&
    css`
      filter: drop-shadow(0 2.89px 23.15px #25272e1a);
    `}
`;

const Savings = styled.section`
  display: flex;
  align-items: center;
  gap: 96px;

  @media (max-width: 1080px) {
    flex-direction: column;
    gap: 64px;
  }
`;

const SavingsBody = styled.div`
  max-width: 528px;
`;

const Guide = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 980px) {
    flex-direction: column;
    gap: 48px;
  }
`;

const GuideShield = styled.div`
  mix-blend-mode: luminosity;

  @media (max-width: 980px) {
    max-width: 240px;
  }
`;

const GuideGuides = styled(Box)`
  // prevent accordion content from increasing width
  max-width: 872px;

  @media (max-width: 768px) {
    padding-right: 48px;
    padding-left: 48px;
  }
`;

const FaqFooter = styled(Text)`
  color: #ffffffcc;
`;

export default function AetnaCvsHealth() {
  const data = useStaticQuery(QUERY);
  const faq = data[`${locale}Faq`].nodes;
  const guides = data[`${locale}Guides`].nodes;
  const [isTexas, setIsTexas] = useState(false);

  //@todo temporary, remove once we are ready to show Aetna
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      navigate('/');
    }
  }, []);
  if (process.env.NODE_ENV === 'production') {
    return null;
  }

  useEffect(() => {
    async function getState() {
      const response = await fetch('https://ipinfo.io/json?token=6c9726ea60487c').catch(
        console.error,
      );

      if (!response.ok) {
        console.error(response);
        return;
      }

      const responseJson = await response.json();

      if (responseJson.region !== 'Texas') return;

      setIsTexas(true);
    }
    getState();
  }, []);

  const valueProps = [
    {
      title: $t({
        id: 'aetna.value-props.1.title',
        defaultMessage: 'Quality care',
      }),
      icon: DoctorSvg,
      text: $t({
        id: 'aetna.value-props.1.text',
        defaultMessage:
          'The coverage you need, plus access to the Aetna® network of local doctors, specialists and hospitals you know and trust.',
      }),
    },
    {
      title: $t({
        id: 'aetna.value-props.2.title',
        defaultMessage: 'Affordable care',
      }),
      icon: TabletSvg,
      text: isTexas
        ? $t({
            id: 'aetna.value-props.2.text.tx',
            defaultMessage:
              'Plans starting at $0, $0 preventive care, $0 in-network walk-in clinic visits, $3 copay for over 200 generic drugs and select plans with $0 PCP visits.*',
          })
        : $t({
            id: 'aetna.value-props.2.text',
            defaultMessage:
              'Plans starting at $0, $0 preventive care, $0 MinuteClinic® visits, $3 copay for over 200 generic drugs and select plans with $0 PCP visits.*',
          }),
    },
    {
      title: $t({
        id: 'aetna.value-props.3.title',
        defaultMessage: 'Extra value',
      }),
      icon: AetnaHandSvg,
      text: $t({
        id: 'aetna.value-props.3.text',
        defaultMessage:
          'A $25 allowance every 3 months to shop over-the-counter CVS Health® brand wellness products. Plus, adult dental and vision with select plans.*',
      }),
    },
    {
      title: $t({
        id: 'aetna.value-props.4.title',
        defaultMessage: 'Financial tools',
      }),
      icon: CalculatorSvg,
      text: $t({
        id: 'aetna.value-props.4.text',
        defaultMessage:
          'Save for taxes, time off,  emergencies and more with Catch, included when you enroll in an Aetna CVS Health plan.',
      }),
    },
  ];

  const benefits = [
    {
      title: $t({
        id: 'aetna.benefits.1.title',
        defaultMessage: 'Health coverage',
      }),
      value: 'Active',
      image: insuranceImage,
      imageShadow: true,
    },
    {
      title: $t({
        id: 'aetna.benefits.2.title',
        defaultMessage: 'Taxes',
      }),
      value: '$3,800',
      image: taxesImage,
    },
    {
      title: $t({
        id: 'aetna.benefits.3.title',
        defaultMessage: 'Goals',
      }),
      value: '$6,500',
      image: goalsImage,
    },
  ];

  const title = $t({
    id: 'aetna.title',
    defaultMessage: 'Aetna CVS Health® plan',
  });
  const description = $t({
    id: 'aetna.desctiption',
    defaultMessage:
      'Get more from your Aetna CVS Health® plan, like exclusive access to financial tools built for freelancers from our partner, Catch.',
  });

  const cta = (
    <Button to={urls.SIGN_UP}>
      {$t({
        id: 'aetna.cta-button',
        defaultMessage: 'Get started',
      })}
    </Button>
  );

  const postalCodeForm = (
    <Zip
      cta={$t({
        id: 'aetna.postal-code-form.button',
        defaultMessage: 'Find plans',
      })}
    />
  );

  const footerTextProps: Partial<TextProps> = {
    element: 'p',
    size: 'xsmall',
  };

  return (
    <Page
      hideBanner
      hideLinks
      metadata={{
        description,
        title,
      }}
      navBorder={false}
      title={title}
    >
      {/* Apply Aetna fonts */}
      <Helmet>
        <body className="aetna-page" />
      </Helmet>
      <Hero>
        <Container>
          <HeroInner>
            <div>
              <Row2>
                <Row2 size="large">
                  <SAetnaCvsHealthLogoSvg aria-label="Aetna CVS Health®" />
                </Row2>
                <Row2 size="small">
                  <Text element="h2" size="xxlarge">
                    {$t({
                      id: 'aetna.hero.pretitle',
                      defaultMessage: 'Individual & Family plans',
                    })}
                  </Text>
                </Row2>
                <Row2 size="xsmall">
                  <Text element="h1" size="xxxxxlarge">
                    {$t({
                      id: 'aetna.hero.title',
                      defaultMessage: 'Health insurance designed for you',
                    })}
                  </Text>
                </Row2>
                <Text element="p" flush size="large">
                  {description}
                </Text>
              </Row2>
              <Row2>{postalCodeForm}</Row2>
              <AetnaCvsHealthSignUpText />
            </div>
            <HeroImage align="center">
              <StaticImage
                src="./images/heart@2x.png"
                alt=""
                loading="eager"
                placeholder="none"
                width={520}
              />
            </HeroImage>
          </HeroInner>
        </Container>
      </Hero>
      <Block2 color="grey">
        <Container>
          <Title
            subtitle={$t({
              id: 'aetna.value-props.subtitle',
              defaultMessage:
                'Freelance fearlessly with quality coverage from Aetna CVS Health and financial tools from Catch. Whether you’re a photographer, a founder or still figuring it out, we’ve got your back.',
            })}
          >
            {$t({
              id: 'aetna.value-props.title',
              defaultMessage: 'Get more from your health plan',
            })}
          </Title>
          <ValuePropsGrid>
            {valueProps.map((item) => (
              <Text align="left" element="figure" key={item.title}>
                <Row2>
                  <item.icon />
                </Row2>
                <figcaption>
                  <Row2 size="xxxsmall">
                    <Text element="h3" size="xlarge">
                      {item.title}
                    </Text>
                  </Row2>
                  <Text color="grey" element="p" flush>
                    {item.text}
                  </Text>
                </figcaption>
              </Text>
            ))}
          </ValuePropsGrid>
          <Row2 size="xsmall">{cta}</Row2>
          <AetnaCvsHealthSignUpText />
        </Container>
      </Block2>
      <Block2>
        <Row2 size="xxlarge">
          <Container>
            <Row2 size="xxlarge">
              <Text align="center" element="section">
                <Title
                  pretitle={$t({
                    id: 'aetna.benefits.pretitle',
                    defaultMessage: 'Benefits beyond insurance',
                  })}
                  subtitle={$t({
                    id: 'aetna.benefits.subtitle',
                    defaultMessage:
                      'Put aside a percentage of each paycheck for taxes, time off and more. Aetna CVS Health members get exclusive access to tools from Catch that streamline savings and help you stay on top of your money.',
                  })}
                >
                  {$t({
                    id: 'aetna.benefits.title',
                    defaultMessage: 'Simplify your finances with Catch',
                  })}
                </Title>
                <Row2 size="large">
                  <BenefitsGrid>
                    {benefits.map((item) => (
                      <Benefit align="left" element="figure" key={item.title}>
                        <Row2 element="figcaption" size="small">
                          <Row2 size="xxxsmall">
                            <Text color="purple" element="h3">
                              {item.title}
                            </Text>
                          </Row2>
                          <Text size="xxlarge">{item.value}</Text>
                        </Row2>
                        <BenefitImage src2x={item.image} $shadow={item.imageShadow} />
                      </Benefit>
                    ))}
                  </BenefitsGrid>
                </Row2>
                <Row2 size="small">{cta}</Row2>
                <AetnaCvsHealthSignUpText />
              </Text>
            </Row2>
            <Savings>
              <StaticImage
                src="./images/photographer@2x.png"
                alt={$t({
                  id: 'aetna.savings.photo-alt',
                  defaultMessage: 'Kevin’s premium $17/mo',
                })}
                placeholder="blurred"
                width={539}
              />
              <SavingsBody>
                <Title>
                  {$t({
                    id: 'aetna.savings.title',
                    defaultMessage: 'Save on your monthly premium',
                  })}
                </Title>
                <Row2 size="large">
                  <Text color="grey" element="div">
                    <Text element="p" size="large">
                      {$t({
                        id: 'aetna.savings.text-1',
                        defaultMessage:
                          'Catch helps you get the biggest discounts by finding the tax credits you qualify for. Savings are automatically applied when you shop.',
                      })}
                    </Text>
                    <Text element="p" flush size="large">
                      {$t({
                        id: 'aetna.savings.text-2',
                        defaultMessage: 'Plus, {link} for free expert support along the way..',
                        data: {
                          link: (
                            <Link to={paths.CONTACT} color="purple">
                              {$t({
                                id: 'aetna.savings.text-2.link',
                                defaultMessage: 'contact Catch',
                              })}
                            </Link>
                          ),
                        },
                      })}
                    </Text>
                  </Text>
                </Row2>
                {postalCodeForm}
              </SavingsBody>
            </Savings>
          </Container>
        </Row2>
      </Block2>
      <Row2 size="large">
        <Boxes>
          <Box color="purple">
            <Container>
              <Guide>
                <GuideShield>
                  <StaticImage
                    src="./images/shield@2x.png"
                    alt=""
                    placeholder="tracedSVG"
                    width={336}
                  />
                </GuideShield>
                <GuideGuides color="white" size="small">
                  <Row2>
                    <Row2 size="xsmall">
                      <Text element="h2" size="xxxlarge">
                        {$t({
                          id: 'aetna.guide.title',
                          defaultMessage: 'Health Enrollment Guide',
                        })}
                      </Text>
                    </Row2>
                    <Text color="grey" element="p" flush>
                      {$t({
                        id: 'aetna.guide.text',
                        defaultMessage:
                          'Want assistance navigating health insurance? Catch is here to help every step of the way.',
                      })}
                    </Text>
                  </Row2>
                  <Accordion
                    items={guides.map((item) => ({
                      title: item.title,
                      content: item.explanation,
                    }))}
                    showCaret
                  />
                </GuideGuides>
              </Guide>
            </Container>
          </Box>
          <Box color="black">
            <Container narrow>
              <Row2 size="large">
                <Text element="h2" size="xxxxlarge">
                  {$t({
                    id: 'FAQsection.title-1',
                    defaultMessage: 'What’s the catch?',
                  })}
                  <br />
                  <Text color="purple">
                    {$t({
                      id: 'FAQsection.title-2',
                      defaultMessage: 'There isn’t one.',
                    })}
                  </Text>
                </Text>
              </Row2>
              <Row2 size="large">
                <div className="dark">
                  <Accordion
                    items={faq.map((item) => ({
                      title: item.title,
                      content: item.explanation,
                    }))}
                    showCaret
                  />
                </div>
              </Row2>
              <FaqFooter element="p" flush>
                {$t({
                  id: 'aetna.faq.guides-cta',
                  defaultMessage: 'Something else? See answers to more questions {link}.',
                  data: {
                    link: (
                      <Link to={paths.GUIDES_HEALTH} color="white">
                        {$t({
                          id: 'aetna.faq.guides-cta.link',
                          defaultMessage: 'here',
                        })}
                      </Link>
                    ),
                  },
                })}
              </FaqFooter>
            </Container>
          </Box>
        </Boxes>
      </Row2>
      <Block2>
        <Container>
          <Row2 size="xlarge">
            <Box color="purple">
              <Text align="center" element="div">
                <Title
                  subtitle={$t({
                    id: 'aetna.plan.subtitle',
                    defaultMessage:
                      'Plus, exclusive access to financial tools from Catch when you join Aetna CVS Health.',
                  })}
                >
                  {$t({
                    id: 'aetna.plan.title',
                    defaultMessage: 'Find the right plan for you',
                  })}
                </Title>
                <Row2>
                  <Zip
                    cta={$t({
                      id: 'aetna.plan.postal-code-form.button',
                      defaultMessage: 'Get covered',
                    })}
                    helpColor="inherit"
                  />
                </Row2>
                <AetnaCvsHealthSignUpText color="inherit" />
              </Text>
            </Box>
          </Row2>
          <Text color="grey" element="footer">
            {isTexas ? (
              <>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.tx.text-1',
                    defaultMessage:
                      '*FOR PLANS STARTING AT $0: Not available in all states. Exclusions & limitations apply.',
                  })}
                </Text>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.tx.text-2',
                    defaultMessage:
                      '*FOR $0 PCP VISITS: Select plans with $0 visits every time you see your primary care provider. Members enrolled in qualified high-deductible health plans must meet their deductible before receiving covered primary care or non-preventive services at no cost-share.',
                  })}
                </Text>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.tx.text-3',
                    defaultMessage:
                      '*FOR $0 WALK-IN CLINIC VISITS: For a list of participating walk-in clinics, use our online provider search tool. Includes select MinuteClinic services. Not all MinuteClinic services are covered or may be covered at negotiated contract rates. Not available in all states. Walk-in appointments aren’t guaranteed. Online scheduling recommended. Check your plan documents for more detail. Members enrolled in qualified high-deductible health plans must meet their deductible before receiving covered primary care or non-preventive services at no cost-share.',
                  })}
                </Text>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.tx.text-4',
                    defaultMessage:
                      '*FOR $3 COPAY: This is not available in California and New Jersey. Pharmacy benefits are administered by an affiliated pharmacy benefit manager, CVS Caremark®.',
                  })}
                </Text>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.tx.text-5',
                    defaultMessage:
                      '*FOR $25 ALLOWANCE: $25 allowance each quarter to use on select CVS Health® brand products. Unused allowance does not carry over to the next quarter. This benefit is not available in California, Missouri or New Jersey.',
                  })}
                </Text>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.tx.text-6',
                    defaultMessage:
                      'In Texas, discount programs are NOT insurance and program features are not guaranteed under the plan contract and may be discontinued at any time.',
                  })}
                </Text>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.tx.text-7',
                    defaultMessage:
                      '*FOR ADULT DENTAL AND VISION: Coverage for select plans. Available in all states except for California, Maryland, Delaware, New Jersey and Utah.',
                  })}
                </Text>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.tx.text-8',
                    defaultMessage:
                      'Health plans are offered or underwritten or administered by Aetna Health Inc. (Texas) (Aetna). Aetna is part of the CVS Health family of companies.',
                  })}
                </Text>
              </>
            ) : (
              <>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.text-1',
                    defaultMessage:
                      '*FOR PLANS STARTING AT $0: Not available in all states. Exclusions & limitations apply.',
                  })}
                </Text>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.text-2',
                    defaultMessage:
                      '*FOR $0 PCP VISITS: Select plans with $0 visits every time you see your primary care provider. Members enrolled in qualified high-deductible health plans must meet their deductible before receiving covered primary care or non-preventive services at no cost-share.',
                  })}
                </Text>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.text-3',
                    defaultMessage:
                      '*FOR $0 MINUTE CLINIC VISITS: For a list of participating walk-in clinics, use our online provider search tool. Includes select MinuteClinic services. Not all MinuteClinic services are covered or may be covered at negotiated contract rates. Not available in all states. Walk-in appointments aren’t guaranteed. Online scheduling recommended. Check your plan documents for more detail. Members enrolled in qualified high-deductible health plans must meet their deductible before receiving covered primary care or non-preventive services at no cost-share.',
                  })}
                </Text>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.text-4',
                    defaultMessage:
                      '*FOR $3 COPAY: This is not available in California and New Jersey. Pharmacy benefits are administered by an affiliated pharmacy benefit manager, CVS Caremark®.',
                  })}
                </Text>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.text-5',
                    defaultMessage:
                      '*FOR $25 ALLOWANCE: $25 allowance each quarter to use on select CVS Health® brand products. Unused allowance does not carry over to the next quarter. This benefit is not available in California, Missouri or New Jersey.',
                  })}
                </Text>
                <Text {...footerTextProps}>
                  {$t({
                    id: 'aetna.footer.text-6',
                    defaultMessage:
                      '*FOR ADULT DENTAL AND VISION: Coverage for select plans. Available in all states except for California, Maryland, Delaware, New Jersey and Utah.',
                  })}
                </Text>
                <Text {...footerTextProps} flush>
                  {$t({
                    id: 'aetna.footer.text-7',
                    defaultMessage:
                      'Health plans are offered or underwritten or administered by Aetna Health of California Inc., Aetna Health Inc. (Florida), Aetna Health Inc. (Georgia), Aetna Life Insurance Company, Aetna Health of Utah Inc., Aetna Health Inc. (Pennsylvania), or Aetna Health Inc. (Texas) (Aetna). Aetna is part of the CVS Health® family of companies.',
                  })}
                </Text>
              </>
            )}
          </Text>
        </Container>
      </Block2>
    </Page>
  );
}
